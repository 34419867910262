/* You can add global styles to this file, and also import other style files */

// Set project variables
$primary: #006ea3;
$primary-active:    								darken($primary, 10%);
$primary-light:    								    #d1e6f0;
$primary-light-dark:							    #24242d;
$primary-inverse:  									#FFFFFF;

$success:       									#239d5b;
$success-active:    							darken($success, 10%);
$info: #0DCAF0;
$info-light: #cff4fc;
$info-inverse: #000000;
$danger: #dc3545;
$danger-light: #f8d7da;
$warning-inverse: #000000;


$app-sidebar-base-bg-color: darken($primary, 20%);;
// $app-sidebar-base-menu-link-icon: white;
$app-sidebar-base-menu-link-active: $primary-active;
$app-sidebar-base-width: 300px;

@import "../node_modules/@bws-dev/ngx-bws-shared/styles/style";
@import "../node_modules/@angular/cdk/overlay-prebuilt.css";

bws-header {
  #sidebar-toggler {
    margin-right: 10px !important;
  }
}

.bg-abstract {
  background-image: url(/assets/images/shapes/abstract-6.svg);
  background-size: cover;
}

gallery {
  background-color: white !important;
  padding: 1.25rem;
}
gallery-thumbs {
  margin-top: 0.75rem;
}

.alert-warning {
  color: darken($warning, 40%);
}
.alert-info {
  color: darken($info, 20%);
}
